export function displayAfterUpdate() {
  if ($('#recent-plans-header').length == 0) {
    return; // not on Updates Tab
  }

  var count = $('#updates .card').data('recent-plans-count')
  if (count == 0) {
    $('#plans').hide()
    $('#recent-plans-header').hide();
  } else {
    $('#plans').show()
    $('#recent-plans-header').show();
  }
}

export function displayAfterPlan () {
  var header = $('#recent-plans-header');
  if ($('#plans').length > 0) {
    $('#plans').show()
    header.show()
  } else {
    header.hide()
  }
}

import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="stream"
export default class extends Controller {
  connect() {
    this.updateStreams()
  }

  updateStreams() {
    if ($('#recent-streams-header').length == 0) {
      return; // not on Stack Tab
    }

    $('#streams .card').each((i, el) => {
      if (i > 2) {
        $(el).remove()
      }
    })
  }
}

import { Controller } from "@hotwired/stimulus"
import * as recentPlans from './recent_plans'

// Connects to data-controller="plan"
export default class extends Controller {
  connect() {
    console.log("plan controller connected");
    recentPlans.displayAfterPlan();
  }
}

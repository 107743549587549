// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"
import "./controllers"
import * as bootstrap from "bootstrap"

import jQuery from "jquery";
window.$ = window.jQuery = jQuery;

// https://github.com/select2/select2/issues/6081#issuecomment-1043871398
import select2 from 'select2';
// import 'select2/dist/css/select2.min.css'
select2();

document.addEventListener('turbo:load', () => {
  $('.orgs-select').select2({
    width: '100%',
    minimumInputLength: 2,
    // tags: true, // allow custom values https://stackoverflow.com/questions/14577014/select2-dropdown-but-allow-new-values-by-user
    ajax: {
      url: '/api/v1/orgs',
      dataType: 'json'
    }
  });

  $('.users-select').select2({
    width: '100%',
    minimumInputLength: 2,
    ajax: {
      url: '/api/v1/users',
      dataType: 'json'
    }
  });

  $('.users-in-org-select').select2({
    width: '100%',
    allowClear: true,
  });
});

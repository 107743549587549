import { Controller } from "@hotwired/stimulus"
import * as recentPlans from './recent_plans'

// 2 ways to import
// import * as hello from './greet'
// hello.greet("me");
// import { greet } from './greet'
// greet("me2")

// Connects to data-controller="update"
export default class extends Controller {
  static targets = [ "main", "header" ]

  connect() {
    console.log("update controller connected");
    recentPlans.displayAfterUpdate();

    this.displayLastUpdate();
  }

  displayLastUpdate() {
    var header = $('#last-update-header');
    if (header.length == 0) {
      return; // not on Stack Tab
    }

    var count = $('#updates .card').length;
    if (count == 0) {
      $('#updates').hide()
      header.hide();
    } else {
      $('#updates').show()
      header.show();
    }

    $('#updates .card').each((i, el) => {
      if (i > 0) {
        $(el).remove()
      }
    })
  }
}

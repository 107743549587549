let Convert = require("ansi-to-html");

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "data", "caret", "terminal" ]

  connect() {
    // colorize log data
    // can find the original colors in getDefaultColors in node_modules/ansi-to-html/lib/ansi_to_html.js
    let colors = {
      1: '#ff7d7d', // original #A00 instead using a lighter red. when terraform errors
      8: '#c1b9b9', // original #555 instead using a whiter color so can see in the black background. when resource field is remove "null" its gray
    }
    let convert = new Convert({colors: colors});

    // https://stackoverflow.com/questions/8488729/how-to-count-the-number-of-lines-of-a-string-in-javascript
    var total_lines = this.dataTarget.innerHTML.split(/\r\n|\r|\n/).length - 1;
    let html = convert.toHtml(this.dataTarget.innerHTML);
  	this.dataTarget.innerHTML = html

    var line_numbers = "";
    for (let i = 0; i < total_lines; i++) {
      line_numbers += i+1 + "\n"
    }
  	this.terminalTarget.setAttribute('data-before', line_numbers);
  	this.scrollToBottom()

    // capture scrollTop position so
    // 1. can keep at to bottom for live tailing (if already at bottom)
    // 2. keep the current position so user is not suddenly move elsewhere
    $('.terminal').on('scroll', function(e) {
      $(document).data('lastScrollTop', $('.terminal').scrollTop());
      let terminal = $('.terminal');
      let atBottom = terminal.scrollTop() + terminal.innerHeight() >= terminal[0].scrollHeight;
      $(document).data('wasAtBottom', atBottom);
    });
  }

  toggle() {
    console.log("toggle this.element", this.element)
    this.caretTarget.classList.toggle("caret-down")
    this.terminalTarget.classList.toggle("hidden")
    console.log("toggle done")
  }

  scrollToBottom() {
    if ($('.stop-stream-polling').length == 1 && $('.stop-stream-polling-initial-load').length == 1) {
      return
    }

  	let terminal = $('.terminal');
  	if (!$(document).data('alreadyAnimated')) {
      // only animate the first time. else it keeps "moving" every ajax call
      terminal.animate({ scrollTop: terminal[0].scrollHeight }, 1000);
  	  $(document).data('alreadyAnimated', true)
  	} else {
  	  if ($(document).data('lastScrollTop')) {
  	    if ($(document).data('wasAtBottom')) {
          terminal.scrollTop(terminal[0].scrollHeight); // tail logs
  	    } else {
  	      // so user is not suddenly moved
    	    terminal.scrollTop($(document).data('lastScrollTop'));
  	    }
  	  } else {
        terminal.scrollTop(terminal[0].scrollHeight); // tail logs
  	  }
  	}
  }
}
